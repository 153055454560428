import { createElement, ForwardedRef, forwardRef, ReactNode } from "react";
import clsx from "clsx";

import Skeleton from "../Skeleton";
import styles from "./style.module.css";

interface ImageWithContentProps {
  imageSrc: string;
  imageSrcSet?: string[];
  imageSizes?: string;
  imageAlt?: string;
  // eslint-disable-next-line
  component?: any;
  innerClassName?: string;
  wrapperClassName?: string;
  pictureWrapperClassName?: string;
  children?: ReactNode;
  skeleton?: boolean;
  loading?: "lazy" | "eager";
}

const ImageWithContent = (
  {
    imageSrc,
    imageSrcSet,
    imageSizes,
    imageAlt,
    component = "div",
    loading = "lazy",
    innerClassName,
    wrapperClassName,
    pictureWrapperClassName,
    children,
    skeleton,
    ...props
  }: ImageWithContentProps,
  // eslint-disable-next-line
  ref?: ForwardedRef<any>
) => {
  return createElement(
    component,
    {
      className: clsx(styles.ImageWithContent, wrapperClassName, skeleton && styles.WithSkeleton),
      ref,
      ...props,
    },
    <>
      <div className={clsx(styles.PictureWrapper, pictureWrapperClassName)}>
        <img
          className={styles.Picture}
          loading={loading}
          src={imageSrc}
          srcSet={imageSrcSet ? imageSrcSet.join(", ") : undefined}
          sizes={imageSizes}
          alt={imageAlt}
        />
      </div>
      <div className={clsx(styles.Content, innerClassName)}>{children}</div>
      {skeleton && <Skeleton className={clsx(styles.Skeleton)} />}
    </>
  );
};

export default forwardRef(ImageWithContent);
