import { cn } from "../lib/utils";

type SkeletonProps = React.PropsWithChildren<
  React.HTMLAttributes<HTMLDivElement> & {
    width?: string | number;
    height?: string | number;
    variant?: "rectangular" | "circular";
  }
>;

function Skeleton({
  className,
  width,
  height,
  variant = "rectangular",
  children,
  ...props
}: SkeletonProps) {
  return (
    <div
      style={{ width, height: variant === "circular" ? width : height }}
      className={cn(
        "inline-block animate-pulse bg-gray-200 [&>*]:invisible",
        !height && variant === "circular" && "h-full",
        variant === "rectangular" && "rounded",
        variant === "circular" && "rounded-full",
        className
      )}
      {...props}
    >
      {children ?? " ‌"}
    </div>
  );
}

export default Skeleton;
